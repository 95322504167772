<template>
  <div class="wrapper-container">
    <div class="describe-container-box">
      <div class="title">数据赋能 ·方案详情</div>
      <div class="sub-title">数据赋能增长策略</div>
      <div class="large-paragraph-describe">
        自主研发大数据中台系统，可应对海量用户实时需求，提供大数据的采集、管理和分析能力，以统一的数据标准、流程规范，加速业务数据化、数据资产化、资产服务化的进程，规范商户、供应商、顾客多方数据隐私管理。同时，集成多触点数据，通过线上到家到店销售数据、线上POS数据等多维度分析，为企业输出差异化数据洞察报告，反哺业务精细化运营。
      </div>
    </div>
    <img
      class="large-image-h492"
      src="@/image/retail/img_data_power_retail@3x.png"
    />
    <div class="large-image-container">
      <div class="func-image-container-data-power">
        <img v-for="func in funcModules" :src="func" :key="func" />
      </div>
    </div>
    <div class="func-module-container">
      <div class="func-title">数据赋能·应用案例</div>
      <div class="func-sub-title">美特好巧用数智报表，激活团队高收益</div>
      <div class="func-describe">
        2022年4月，全球蛙携手美特好开启社区团购业务，在太原城区受疫情影响封闭的情况下，迅速招募小区团长、新建社群，高效完成太原市民防疫保供支撑工作。疫情平稳后，此项业务依旧稳步进展，成为美特好一个常态型线上增长业务。该业务的持续稳定开展，离不开数智报表的强大作用。
      </div>
      <div class="data-power-implement-process">实施过程</div>
      <div class="func-describe">
        全球蛙联合美特好，按地域和门店重新进行了组织的分配，明确了作战目标，并根据促成目标达成的各项成果指标，分别制定了不同的数智报表，如社群分销记分表、团长招募表、团长分销激活表、门店导购分销表、周TOP品分销表等，报表上会根据各指标达成情况标注不同颜色，让导购、团长、店长以及美特好超市的管理者一眼见输赢。
      </div>
    </div>
    <div class="large-image-h564">
      <img src="@/image/retail/img_ reportforms_retail@3x.png" />
    </div>
    <action-sheet module="新零售数据赋能">
      <template #extra-title>
        <div class="extra-title">相关文章</div>
      </template>
    </action-sheet>
  </div>
</template>

<script>
export default {
  data: () => ({
    funcModules: [
      require("@/image/retail/img_salesroom_retail@3x.png"),
      require("@/image/retail/img_screen_retail@3x.png"),
      require("@/image/retail/img_statement_retail@3x.png"),
    ],
  }),
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/solution.common.scss";
.func-module-container {
  padding-bottom: 0 !important;
}
.large-image-container {
  background-color: $bgColorfff !important;
  padding-top: 0 !important;
}
.func-describe {
  margin-top: 0 !important;
}
.func-sub-title {
  margin-bottom: 40px !important;
}
</style>
